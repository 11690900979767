<template>
	<div class="login-container">
		<el-form
			ref="loginForm"
			:model="form"
			:rules="rules"
			class="login-form"
			auto-complete="on"
			label-position="left"
		>
			<div class="title-container">
				<div class="logo-container">
					<img src="../../assets/login_images/logo.png" height="45" alt="" />
				</div>
				<h3 class="title">临床研究项目管理系统(CTMS)</h3>
			</div>

			<el-form-item prop="username">
				<span class="svg-container">
					<svg-icon icon-class="user" />
				</span>
				<el-input
					v-model="form.username"
					placeholder="请输入用户名"
					name="username"
					size="small"
					type="text"
					auto-complete="on"
				/>
			</el-form-item>

			<el-form-item prop="password">
				<span class="svg-container">
					<svg-icon icon-class="password" />
				</span>
				<el-input
					v-model="form.password"
					type="password"
					size="small"
					placeholder="请输入密码"
					name="password"
					auto-complete="on"
					@keyup.enter.native="handleSubmit"
				/>
			</el-form-item>
			<el-button
				:loading="loading"
				round
				size="large"
				type="primary"
				@click.native.prevent="handleSubmit"
			>
				登录
			</el-button>
		</el-form>
	</div>
</template>
<script>
import { dataToSha } from "@/utils/util"

export default {
	name: "Login",
	data() {
		return {
			form: {
				username: "",
				password: "",
				rememberMe: true
			},
			rules: {
				username: [
					{ required: true, trigger: "blur", message: "请输入用户名" }
				],
				password: [{ required: true, trigger: "blur", message: "请输入密码" }]
			}
		}
	},
	created() {},
	methods: {
		handleSubmit() {
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						const { username, password } = this.form
						this.loading = true
						await this.$store.dispatch("user/login", {
							username,
							password: dataToSha(password)
						})
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.login-container {
	.el-input {
		display: inline-block;
		height: 44px;
		width: 85%;

		input {
			border: 0px;
			-webkit-appearance: none;
			border-radius: 0px;
			padding: 12px 5px 0 15px;
			height: 44px;
			font-size: 14px;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
			}
		}
	}
}
</style>

<style lang="less" scoped>
.login-container {
	min-height: 100%;
	width: 100%;
	background-image: url("~@/assets/login_images/login-bg.jpg");
	background-size: cover;
	background-color: #394a6f;
	background-repeat: no-repeat;
	background-position: center;

	overflow: hidden;

	.login-form {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		max-width: 100%;
		margin: 0 auto;
		overflow: hidden;
		background-color: #fff;
		border-radius: 8px;
		padding: 20px 40px 40px;
		width: 440px;
	}

	.logo-container {
		height: 80px;
		padding-top: 20px;
		text-align: center;

		img {
			vertical-align: middle;
		}
	}

	.el-form-item {
		border-bottom: 1px solid #ccc;
	}

	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;

		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}

	.svg-container {
		.svg-icon {
			width: 1.5em;
			height: 1.5em;
			margin-left: 20px;
		}
	}

	.title-container {
		position: relative;

		.title {
			font-size: 22px;
			line-height: 40px;
			color: #333;
			margin: 0px auto 20px;
			text-align: center;
			font-weight: bold;
		}

		.set-language {
			color: #fff;
			position: absolute;
			top: 3px;
			font-size: 18px;
			right: 0px;
			cursor: pointer;
		}
	}

	.show-pwd {
		position: absolute;
		right: 10px;
		top: 7px;
		font-size: 16px;
		color: $dark_gray;
		cursor: pointer;
		user-select: none;
	}

	.thirdparty-button {
		position: absolute;
		right: 0;
		bottom: 6px;
	}

	.check-container {
		height: 44px;
		padding-right: 16px;
		float: right;

		.el-checkbox {
			color: #eeeeee;
		}
	}
}

.el-button--primary {
	width: 100%;
	background-color: #2d8cf0;
	border-color: #2d8cf0;
	margin: 40px auto 0;
	display: block;
}
</style>
